.header {
  @apply sticky top-0 z-30 w-full;

  transform: translate3d(0, 0, 0);
  transition: all ease-out 0.4s;

  &:global(.invisible) {
    transform: translate3d(0, -100%, 0);
  }

  .leftSection {
    flex: 1 1 100%;
  }

  .middleSection {
    flex: 1 1;
    white-space: nowrap;
  }

  .rightSection {
    flex: 1 0;
  }

  .headerContainer {
    @apply w-full h-full relative;
    @apply flex items-center gap-x-8;
    @apply container mx-auto py-6;

    .mNavOpenButton {
      @apply cursor-pointer;
    }
  }

  .headerNavigation {
    @apply flex items-center flex-nowrap;
    @apply w-full;

    .navItemList {
      @apply list-none;
      @apply flex items-center flex-grow flex-shrink;

      .navItem {
        &:before {
          content: none;
        }

        @apply p-0;
        @apply m-0 mr-[2rem];

        @apply flex items-center cursor-pointer;
        @apply text-15-23 font-medium;
        @apply text-purple hover:text-purple-darkest;

        &.inactiveNavItem {
          @apply opacity-50;
        }

        &.navItemBlue {
          @apply text-white;
        }
      }
    }
  }

  .navPopout {
    @apply w-full;
    @apply bg-white;
    @apply fixed mt-0 top-0 z-30;
    box-shadow: 0 20px 20px -20px #00000029;

    .popoutContainer {
      @apply w-full h-full;
      @apply flex gap-x-8;
      @apply pl-0 pr-0 py-8;
      @apply container mx-auto;

      > div {
        @apply w-1/3;
      }

      .popoutSection {
        @apply text-15-23;

        .popoutSectionTitle {
          @apply mb-[0.875rem] font-semibold;
        }

        .popoutSectionNavItemHeader {
          @extend .popoutSectionNavItem;
          @apply text-purple hover:text-purple-button-hover;
        }

        .popoutSectionNavItem {
          @apply mb-[0.875rem];
          @apply text-purple hover:text-purple-button-hover;
        }
      }
    }
  }

  .mobileNavigation {
    @apply absolute top-0 left-0 z-50;
    @apply w-screen;
    @apply bg-white text-purple;

    .mNavContainer {
      @apply relative w-full overflow-scroll;

      .mNavHeader {
        @apply flex items-center justify-between;
        @apply container mx-auto py-6 pr-8;

        .mNavCloseButton {
          @apply cursor-pointer;
        }
      }

      .mNavEntries {
        @apply container mx-auto pb-5;

        ul {
          @apply mt-4;
          @apply list-none;
          @apply flex flex-col;

          .mNavEntry {
            &:before {
              content: none;
            }

            @apply h-12 flex align-middle;

            a {
              @apply flex items-center;
            }

            @apply p-0;
            @apply text-18-27 font-medium;
            @apply cursor-pointer;

            svg {
              @apply w-8 h-3;
            }

            &.small {
              @apply text-15-23 font-normal;
            }
          }
        }
      }

      .bottomSection {
        @apply w-full;

        .mNavButtonSection {
          @apply p-8;
          @apply flex items-center justify-between gap-x-4;
          @apply max-w-full md:max-w-md;

          a {
            width: 100%;
          }
        }

        .mNavHelpSection {
          @apply bg-gray-lighter;
          @apply w-full;

          .mNavHelpSectionContainer {
            @apply px-8 py-10;
            @apply max-w-[280px] md:max-w-md;

            .mNavHelpSectionTitle {
              @apply font-semibold;
            }
          }
        }
      }
    }
  }
}
