.zoomModal {
  @apply w-full h-full;
  @apply fixed left-1/2 top-1/2;
  @apply -translate-x-1/2 -translate-y-1/2;
  @apply z-50 bg-transparent;

  .zoomImageWrapper {
    @apply relative;
    @apply w-full h-full sm:w-[80%] sm:h-[80%];
  }
}

.imageHover {
  @apply hover:cursor-zoom-in;
}

.closeButton {
  @apply absolute top-8 right-8;
  @apply text-20-30 font-bold text-white;
}
