.sectionWrapper {
  div {
    @apply container relative;
    img {
      @apply container w-full mx-auto rounded-2xl;
    }
  }
}

.playerWrapper {
  @apply relative w-full h-full;
}
