.sectionWrapper {
  @apply w-full py-16;

  .sectionInner {
    @apply container flex flex-wrap items-center justify-between;

    &.reverse {
      @apply flex-row-reverse;
    }

    .content {
      @apply px-0 md:px-12;

      &.halfWidth {
        @apply basis-full flex-shrink flex-grow-0;
        @apply md:basis-1/2;
      }

      &.fullWidth {
        @apply w-full px-0 basis-full;
      }

      .headline {
        @apply text-24-35 lg:text-40-55 font-medium;
        @apply mb-4;
      }

      .headlineSmall {
        @apply text-24-35  font-semibold;
        @apply mb-4;
      }

      .teaser {
        @apply text-15-23 lg:text-24-35;
      }
    }

    .mediaWrapper {
      @apply basis-full flex-shrink-0 flex-grow-0 self-stretch;
      @apply md:basis-1/2 mb-8 md:mb-0;

      &.video {
      }
      &.image {
        @apply min-h-[13rem];
      }

      &.mediaPadding {
        @apply sm:px-12;

        &.image {
          @apply lg:min-h-[30rem];
        }
      }

      &.mediaPaddingRight {
        @apply pr-0 md:pr-12;
      }

      &.mediaPaddingLeft {
        @apply pl-0 md:pl-12;
      }

      .media {
        @apply relative flex items-center overflow-hidden w-full;

        &.video {
          @apply h-auto lg:h-full;
        }
        &.image {
          @apply h-full;
        }

        &.shadow {
          @apply shadow-2xl;
        }

        &.rounded {
          @apply rounded-2xl;
        }

        &.roundedRight {
          @apply rounded-l-none;
        }

        &.roundedLeft {
          @apply rounded-r-none;
        }
      }
    }
  }
}

.divWrapper {
  @apply py-16;

  .sectionInner {
    @apply flex flex-wrap items-center justify-between;

    &.reverse {
      @apply flex-row-reverse;
    }

    .content {
      @apply px-4 sm:px-12;

      &.halfWidth {
        @apply basis-full flex-shrink-0 flex-grow-0;
        @apply md:basis-1/2;
      }

      &.fullWidth {
        @apply basis-full;
      }

      .headline {
        @apply text-24-35 lg:text-40-55 font-medium;
        @apply mb-4;
      }

      .headlineSmall {
        @apply text-24-35  font-semibold;
        @apply mb-4;
      }

      .teaser {
        @apply text-15-23 lg:text-24-35;
      }
    }

    .mediaWrapper {
      @apply basis-full flex-shrink-0 flex-grow-0 self-stretch min-h-[13rem];
      @apply md:basis-1/2 mb-8 md:mb-0;

      &.mediaPadding {
        @apply px-4 sm:px-16;

        &.image {
          @apply lg:min-h-[30rem];
        }
      }

      &.mediaPaddingRight {
        @apply pr-8;
      }

      &.mediaPaddingLeft {
        @apply pl-8;
      }

      .media {
        @apply relative flex items-center overflow-hidden w-full h-full;

        &.shadow {
          @apply shadow-2xl;
        }

        &.rounded {
          @apply rounded-2xl;
        }

        &.roundedRight {
          @apply rounded-l-none;
        }

        &.roundedLeft {
          @apply rounded-r-none;
        }
      }
    }
  }
}
