.wrapper {
  @apply relative self-center;
}

.videoElement {
  @apply rounded-2xl overflow-hidden;
}

.buttonWrapper {
  @apply w-full h-full;
  @apply absolute inset-0 cursor-pointer;

  .bgCircle {
    @apply absolute top-1/2 left-1/2;
    @apply transform -translate-x-1/2 -translate-y-1/2;
    @apply w-32 h-32 rounded-full;
    @apply bg-yellow stroke-white fill-white;
    @apply p-4 pl-10 opacity-70 group-hover:opacity-80;
  }

  .icon {
    @apply absolute top-1/2 left-1/2;
    @apply w-20 h-20;
    @apply stroke-white fill-white opacity-100;

    &.play {
      @apply transform -translate-x-[40%] -translate-y-1/2;
    }

    &.pause {
      @apply transform -translate-x-[50%] -translate-y-1/2;
    }
  }
}
