.footer {
  @apply w-full bg-purple text-white;
  @apply text-18-27;

  .hr {
    @apply w-full h-[1px] bg-purple-border;
    @apply my-16;
  }

  .careerWrapper {
    @apply w-full pt-4 lg:pt-16;
    @apply md:container;
  }

  .sectionTop {
    @apply container mx-auto w-full pt-4 lg:pt-16;
    @apply grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-16;

    .linkSection {
      @apply col-span-1;
      &:last-of-type {
        @apply lg:col-span-2;
      }

      @apply cursor-pointer;
      @apply flex-shrink-0 flex-grow;

      .title {
        @apply font-semibold mt-10 lg:mt-0;
      }

      .navItems {
        @apply flex flex-wrap mt-0 lg:mt-2;

        .navItem {
          @apply w-full mt-2;

          a {
            @apply flex items-center;
          }
        }
      }

      .socialmediaLink {
        @apply relative mr-4;
      }
    }
  }

  .sectionBottom {
    @apply bg-purple;

    .sectionBottomInner {
      @apply container mx-auto py-6;
      @apply flex flex-wrap items-center;

      .bottomContent {
        @apply w-full flex items-end gap-4 flex-wrap;

        .logoSection {
          @apply w-full lg:w-auto flex items-end flex-wrap gap-4;
        }
      }

      .companyInformation {
        @apply w-full lg:w-auto mr-4 mt-4 lg:mt-0;
      }

      .links {
        @apply w-full lg:w-auto mt-4 lg:mt-0 mb-6 lg:mb-0 lg:mr-6;
        @apply flex items-center flex-1 flex-wrap lg:flex-nowrap justify-end;

        .separator {
          @apply hidden lg:block mx-2;
        }

        a {
          @apply w-full lg:w-auto mt-2 lg:mt-0;
        }
      }
    }
  }
}
